import React, { Component } from "react";
import axios from "@/axios";
//import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import { applyVueInReact, withVueRouter } from "vuereact-combined";
import "@/assets/loading.scss";
import "./../../assets/css/movie/movieList.css";
class MoviesList extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      loading: true,
      movieList: [],
      processId: "",
    };
  }
  componentDidMount() {
    axios
      .get("api/v2/services/movies/nowshowing")
      .then((data) => {
        this.setState({
          movieList:
            data.data.response[0].GetMovieNowShowingResponse[0]
              .GetMovieNowShowingResult[0].Movies[0].MovieList,
          processId:
            data.data.response[0].GetMovieNowShowingResponse[0]
              .GetMovieNowShowingResult[0].ProcessId[0],
        });
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }
  componentDidUpdate() {}

  movieClicked = (movieId) => {
    this.props.$vueRouter.push(
      `/movie-show-time/${movieId}/${this.state.processId}`
    );
  };

  fetchMovieList = () => {};
  render() {
    return (
      <>
        <div className="container">
          <div className="box-second-row p-4">
            <h4>Movies Showing Now</h4>
            {this.state.movieList.length > 0 ? (
              <div className="row mt-5">
                {this.state.movieList.map((movie) => {
                  return (
                    <div
                      className=" movie-col col-12 col-sm-6 col-md-4 col-lg-3"
                      key={movie.MovieId}
                    >
                      <div
                        className="movie-polaroid cursor-pointer"
                        onClick={() => {
                          this.movieClicked(movie.MovieId[0]);
                        }}
                      >
                        <div className="movie-body">
                          <div className="movie-poster">
                            <img
                              src={movie.Poster[0]}
                              alt={movie.MovieName[0]}
                            />
                          </div>
                        </div>
                        <div className="movie-footer">
                          <h5>{movie.MovieName[0]}</h5>
                          <p>{movie.Genre[0]}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="card-loader"></div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withVueRouter(MoviesList);
